



























































import { Getter, Action } from 'vuex-class';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import UserModal from '@/components/supervisor/UserModal.vue';
import { parse, format, setGlobalDateI18n } from 'fecha';
// @ts-ignore
import { debounce } from 'lodash';
function Debounce(delay: number) {
    return (target: any, prop: string) => {
        return {
            configurable: true,
            enumerable: false,
            value: debounce(target[prop], delay),
        };
    };
}

@Component({
    components: {
        ElButton: () => import('@/components/elements/Button.vue'),
        TableBase: () => import('@/components/table/TableBase.vue'),
        Spinner: () => import('@/components/elements/Spinner.vue'),
    },
})
export default class UserManagement extends Vue {
    @Getter('account/all') users!: Auth[];
    @Getter('user/isAdmin') isAdmin!: boolean;
    @Getter('pageLoading') pageLoading!: boolean;
    // pageLoading: boolean = true;

    @Action('account/indexUsersInCompany') indexUsers!: (payload: { companyId: number; q?: string; after: Array<string | number> | null }) => Promise<Auth[]>;

    @Prop() companyId?: number;

    i18n: string = 'views.company';

    searchString: string = '';

    filter = {
        searchString: '',
    };

    localUsers: Auth[] = [];

    tableLayout: object = {
        settings: {
            locale_path: `${this.i18n}.table`,
            max_height: 'calc(100% - 110px)',
            clickable: true,
        },
    };

    created() {
        setGlobalDateI18n({
            monthNamesShort: ['jan', 'feb', 'maa', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
        });
    }

    mounted() {
        const tableStructure: object[] = [
            {
                key: this.$t(`${this.i18n}.user_management.table.columns.name`),
                value: (model: Auth) => `${model.first_name} ${model.last_name}`,
                // width: 75,
            },
            {
                key: this.$t(`${this.i18n}.user_management.table.columns.added_date`),
                value: (model: Auth) => format(parse(model.created_at, 'isoDateTime')!, 'D MMM YYYY'),
            },
            {
                key: '',
                alignRight: true,
                value: (model: Auth) => (this.isAdmin ? `<span class="text-blue-dark border-blue-dark hover:border-transparent transition-colors border-b">Gebruiker bekijken</span>` : ''),
            },
        ];

        this.tableLayout = {
            ...this.tableLayout,
            structure: tableStructure,
        };
    }

    @Watch('users', { immediate: true })
    productsChanged(val: Auth[]) {
        this.localUsers = val;
    }

    clearTextFilter() {
        this.filter.searchString = '';
        this.loadMore(false);
    }

    @Debounce(500)
    loadMore(after: boolean) {
        if (this.companyId) {
            this.indexUsers({ companyId: this.companyId, after: after ? this.lastAfter : null, q: this.filter.searchString });
        }
    }

    userClicked(user: Auth) {
        if (this.isAdmin) {
            this.showModal(user);
        }
    }

    private showModal(user: Auth | null = null) {
        this.$modal.show(UserModal, { user }, { adaptive: true, width: user ? '800' : '500', height: 'auto' });
    }

    get lastAfter(): Array<string | number> {
        const total = this.localUsers.length;
        const last: Auth = this.localUsers[total - 1];
        if (last && last.sort) {
            return last.sort;
        }

        return [];
    }
}
