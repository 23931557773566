

















































import { Action, Getter } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
// @ts-ignore
import { cloneDeep } from 'lodash';

@Component({
    components: {
        ModalBase: () => import('@/components/elements/Modal.vue'),
        FormBase: () => import('@/components/forms/Base.vue'),
        FormInput: () => import('@/components/forms/Input.vue'),
        FormButton: () => import('@/components/forms/Button.vue'),
    },
})
export default class UserModal extends Vue {
    @Prop() user?: Auth | undefined;
    @Prop({ default: false }) supervisor?: boolean;

    @Action('company/inviteUser') inviteUser!: (payload: InvitePayload) => Promise<Auth>;
    @Action('account/update') updateUser!: (payload: Auth) => Promise<Auth>;
    @Action('user/forgotPassword') forgotPassword!: (payload: ForgotPasswordPayload) => Promise<Auth>;

    @Getter('company/viewing') company?: Company;

    $refs!: Vue['$refs'] & {
        updateForm: FormClass;
    };

    personalInfo = {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
    };

    i18n: string = 'user_modal';

    localUser: Auth | undefined | null = null;

    payload: InvitePayload = {
        email: '',
        first_name: '',
        last_name: '',
        roles: [1], // normal user
        locale: 'nl_BE',
        company_id: -1,
    };

    forgotPasswordLoading: boolean = false;
    forgotPasswordSuccess: boolean = false;
    forgotPasswordServerError: boolean = false;

    changeStatusLoading: boolean = false;
    changeStatusSuccess: boolean = false;
    changeStatusServerError: boolean = false;

    created() {
        if (this.supervisor) {
            this.i18n = `admin.supervisor_management.user_modal.${this.update ? 'update' : 'add'}`;
            this.payload.roles = [2];
            this.payload.company_id = null;
        } else {
            this.i18n = `supervisor.user_management.user_modal.${this.update ? 'update' : 'add'}`;
        }

        if (this.company && this.company.id) {
            this.payload.company_id = this.company?.id;
        }
    }

    mounted() {
        this.localUser = this.user;

        if (this.localUser) {
            this.mapLocalData(this.localUser);
        }
    }

    mapLocalData(user: Auth) {
        this.personalInfo.email = user.email;
        this.personalInfo.first_name = user.first_name;
        this.personalInfo.last_name = user.last_name;
        this.personalInfo.phone = user.phone;
    }

    sendInvite() {
        this.inviteUser(this.payload).then((r: any) => {
            this.$emit('close');
            this.$toasted.show('Uitnodiging verstuurd', {
                position: 'bottom-left',
                duration: 5000,
                action: {
                    text: '',
                    class: 'close',
                    onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                    },
                },
            });
        });
    }

    updateSubmit() {
        const $form: FormClass = this.$refs.updateForm;

        const payload = this.localUser;

        if (payload) {
            payload.email = this.personalInfo.email;
            payload.first_name = this.personalInfo.first_name;
            payload.last_name = this.personalInfo.last_name;
            payload.phone = this.personalInfo.phone;

            this.updateUser(payload)
                .then((r: Auth) => {
                    this.localUser = r;
                    $form.handleSuccess();
                })
                .catch((e: ErrorResponse) => {
                    $form.handleSuccess();
                });
        }
    }

    toggleActiveState() {
        const payload = this.localUser;
        this.changeStatusLoading = true;
        this.changeStatusSuccess = false;
        this.changeStatusServerError = false;

        if (payload) {
            payload.active = !payload.active;

            this.updateUser(payload)
                .then((r: Auth) => {
                    this.localUser = r;
                    this.changeStatusLoading = false;
                    this.changeStatusSuccess = true;
                })
                .catch((e: ErrorResponse) => {
                    this.changeStatusServerError = true;
                });
        }
    }

    forgotPasswordSubmit() {
        this.forgotPasswordLoading = true;
        this.forgotPasswordSuccess = false;
        this.forgotPasswordServerError = false;

        const payload = {
            email: this.personalInfo.email,
        };
        this.forgotPassword(payload)
            .then(() => {
                this.forgotPasswordLoading = false;
                this.forgotPasswordSuccess = true;
            })
            .catch((e: ErrorResponse) => (this.forgotPasswordServerError = true));
    }

    get update() {
        return this.user !== null;
    }
}
